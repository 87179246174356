define("frontend/models/outbound-call-analytic", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // query by date range
    startTime: (0, _model.attr)('date'),
    endTime: (0, _model.attr)('date'),
    // query by role
    role: (0, _model.attr)('string'),
    // query by user
    userId: (0, _model.attr)('string'),
    // query phone number info
    phoneNumber: (0, _model.attr)('string'),
    outboundCallResults: (0, _model.attr)(),
    callerIdRepStats: (0, _model.attr)(),
    callerIdRepPhoneInfo: (0, _model.attr)(),
    callSessionResults: (0, _model.attr)(),
    organizationTeamDialResults: (0, _model.attr)(),
    organizationPowerDialResults: (0, _model.attr)(),
    organizationAndiDialResults: (0, _model.attr)(),
    save: function save() {
      // clear the result data prior to saving
      this.set('outboundCallResults', null);
      this.set('callerIdRepStats', null);
      this.set('callerIdRepPhoneInfo', null);
      this.set('callSessionResults', null);
      this.set('organizationTeamDialResults', null);
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});