define("frontend/pods/caller-id-reputation-dashboard/index/route", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CallerIdReputationDashboardRoute = Ember.Route.extend({
    requiresLogin: true,
    authorizedRoles: ['admin'],
    model: function model() {
      var userId;
      var id = 'singleton-reputation';
      var today = (0, _moment.default)().startOf('day').toDate();
      var metrics = this.store.peekRecord('outbound-call-analytic', id);

      if (!this.get('authenticationService.isAdmin')) {
        userId = this.get('authenticationService.activeUserId');
      }

      this.store.pushPayload({
        outbound_call_analytic: {
          id: id,
          userId: userId,
          start_time: today.toISOString(),
          phone_number: null
        }
      });
      metrics = this.store.peekRecord('outbound-call-analytic', id);
      metrics.save();
      return metrics;
    }
  });
  var _default = CallerIdReputationDashboardRoute;
  _exports.default = _default;
});