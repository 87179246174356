define("frontend/pods/lead-lists/lead-list/index/controller", ["exports", "frontend/utils/file-downloader"], function (_exports, _fileDownloader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LeadListIndexController = Ember.Controller.extend({
    direction: Ember.computed(function () {
      return true;
    }),
    sortBy: 'lead_last_name',
    sortOptions: [{
      value: 'lead_last_name',
      text: 'Sort: Last Name'
    }, {
      value: 'company_name',
      text: 'Sort: Company Name'
    }, {
      value: 'last_dialed_at',
      text: 'Sort: Last Dialed'
    }, {
      value: 'dial_count',
      text: 'Sort: Dial Count'
    }],
    _searchFn: function _searchFn() {
      this.set('sort_by', this.sortBy);
      this.set('direction', this.direction);
      return this.model.leads.find('lead', {
        lead_list_id: this.get('model.id'),
        sort_by: this.sortBy,
        direction: this.direction
      }); // always sorted by lead_list_id no matter what sort option is selected
    },
    _performSort: Ember.observer('sortBy', function () {
      return Ember.run.debounce(this, this._searchFn, 700);
    }),
    _performSortDirection: Ember.observer('direction', function () {
      return Ember.run.debounce(this, this._searchFn, 700);
    }),
    actions: {
      toggleDirection: function toggleDirection() {
        this.toggleProperty('direction');
      },
      export: function _export() {
        var id = this.model.id;
        var adapter = this.store.adapterFor('application');
        var url = "".concat(adapter.host, "/api/v1/lead_lists/").concat(id, ".csv");
        var headerData = this.store.adapterFor('application').get('headerData');

        var fileDownloader = _fileDownloader.default.create({
          url: url,
          method: 'get',
          data: {},
          headerData: headerData
        });

        return fileDownloader.execute();
      }
    }
  });
  var _default = LeadListIndexController;
  _exports.default = _default;
});