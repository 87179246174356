define("frontend/pods/organizations/organization/webhooks/new/route", ["exports", "frontend/pods/base/new/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var OrganizationWebhookNewRoute = _route.default.extend({
    model: function model() {
      return this.store.createRecord('webhook');
    },
    afterSave: function afterSave() {
      return this.transitionTo('organizations.organization.webhooks');
    },
    afterCancel: function afterCancel() {
      return this.transitionTo('organizations.organization.webhooks');
    }
  });

  var _default = OrganizationWebhookNewRoute;
  _exports.default = _default;
});