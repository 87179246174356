define("frontend/pods/organizations/organization/webhooks/webhook/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RE06C6Un",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"col-md-12 bg-white\"],[12],[2,\"\\n  \"],[10,\"h3\"],[14,0,\"pull-left innerAll\"],[12],[2,\"Edit Webhook: \"],[1,[35,0,[\"name\"]]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"pull-right innerAll\"],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"icon\",\"text\",\"isWaiting\",\"isSuccess\",\"onClick\"],[\"save\",\"Save Changes\",[35,0,[\"isSaving\"]],\"true\",[30,[36,2],[[32,0],[30,[36,1],[\"save\"],null]],null]]]]],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"text\",\"onClick\"],[\"Cancel\",[30,[36,2],[[32,0],[30,[36,1],[\"cancel\"],null]],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"clearfix\"],[12],[13],[2,\"\\n  \"],[1,[30,[36,4],null,[[\"model\"],[[35,0]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"model\",\"route-action\",\"action\",\"ui-button\",\"partial/webhook/form\"]}",
    "meta": {
      "moduleName": "frontend/pods/organizations/organization/webhooks/webhook/edit/template.hbs"
    }
  });

  _exports.default = _default;
});