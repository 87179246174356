define("frontend/pods/components/partial/webhook/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dWqbX9qk",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"model\"],[[35,0]]]]],[2,\"\\n\"],[10,\"form\"],[12],[2,\"\\n\"],[6,[37,3],null,[[\"title\"],[\"Name\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"type\",\"class\",\"value\",\"placeholder\"],[\"text\",\"form-control\",[35,0,[\"name\"]],\"(name for the webhook)\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,3],null,[[\"title\"],[\"URL\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"type\",\"class\",\"value\",\"placeholder\"],[\"text\",\"form-control\",[35,0,[\"url\"]],\"(webhook url)\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"model\",\"input\",\"error-list\",\"ui-form-field\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/partial/webhook/form/template.hbs"
    }
  });

  _exports.default = _default;
});