define("frontend/pods/caller-id-reputation-dashboard/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CallerIdReputationDashboardController = Ember.Controller.extend({
    outboundCallResults: Ember.computed.alias('model.outboundCallResults'),
    callerIdRepStats: Ember.computed.alias('model.callerIdRepStats'),
    startTime: Ember.computed.alias('model.startTime'),
    endTime: Ember.computed.alias('model.endTime'),
    userId: Ember.computed.alias('model.userId'),
    startDate: Ember.computed('startTime', {
      get: function get() {
        var time = this.startTime;

        if (time) {
          return moment(time).startOf('day').toDate();
        }
      },
      set: function set(key, value) {
        console.log('set start date', value);

        if (value) {
          value = moment(value).startOf('day').toDate();
        }

        this.set('startTime', value);
        var time = this.startTime;

        if (time) {
          return moment(time).startOf('day').toDate();
        }
      }
    }),
    endDate: Ember.computed('endTime', {
      get: function get() {
        var time = this.endTime;

        if (time) {
          return moment(time).startOf('day').toDate();
        }
      },
      set: function set(key, value) {
        if (value) {
          value = moment(value).endOf('day').toDate();
        }

        this.set('endTime', value);
        var time = this.endTime;

        if (time) {
          return moment(time).endOf('day').toDate();
        }
      }
    }),
    reducedOutboundCallResults: Ember.computed('outboundCallResults.records', function () {
      var records = this.outboundCallResults.records;

      if (!records) {
        return [];
      }

      var uniqueFromPhones = {};
      var reducedRecords = [];
      records.forEach(function (record) {
        var fromPhone = record.from_phone;

        if (!uniqueFromPhones[fromPhone]) {
          uniqueFromPhones[fromPhone] = true;
          reducedRecords.push(record);
        }
      });
      return reducedRecords;
    }),
    actions: {
      updateFilter: function updateFilter() {
        return this.model.save();
      },
      userSelected: function userSelected(user) {
        this.set('userId', user && user.id);
      }
    }
  });
  var _default = CallerIdReputationDashboardController;
  _exports.default = _default;
});