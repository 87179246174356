define("frontend/pods/organizations/organization/webhooks/webhook/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var WebhookRoute = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('webhook', params.webhook_id);
    }
  });
  var _default = WebhookRoute;
  _exports.default = _default;
});