define("frontend/pods/caller-id-reputation-dashboard/phone-number/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CallerIdReputationDashboardPhoneNumberController = Ember.Controller.extend({
    callerIdRepPhoneInfo: Ember.computed.alias('model.callerIdRepPhoneInfo'),
    topTenLogChecks: Ember.computed('callerIdRepPhoneInfo', function () {
      var logChecks = this.get('callerIdRepPhoneInfo.log_checks');

      if (!logChecks) {
        return [];
      }

      var slicedLogs = logChecks.slice(0, 9);
      return slicedLogs.map(function (logCheck) {
        var id = logCheck.id,
            phone_id = logCheck.phone_id,
            service = logCheck.service,
            flagged = logCheck.flagged,
            content = logCheck.content,
            date = logCheck.date;
        var decodedContent = JSON.stringify(JSON.parse(content), null, 2);
        return {
          id: id,
          phone_id: phone_id,
          service: service,
          flagged: flagged,
          content: decodedContent,
          date: date
        };
      });
    })
  });
  var _default = CallerIdReputationDashboardPhoneNumberController;
  _exports.default = _default;
});