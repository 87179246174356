define("frontend/pods/organizations/organization/webhooks/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "68+FqopD",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"col-md-12 bg-white\"],[12],[2,\"\\n  \"],[10,\"h3\"],[14,0,\"pull-left innerAll\"],[12],[2,\"Create a Webhook\"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"pull-right innerAll\"],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"icon\",\"text\",\"isWaiting\",\"isSuccess\",\"onClick\"],[\"save\",\"Save Webhook\",[35,2,[\"isSaving\"]],\"true\",[30,[36,1],[[32,0],[30,[36,0],[\"save\"],null]],null]]]]],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"text\",\"onClick\"],[\"Cancel\",[30,[36,1],[[32,0],[30,[36,0],[\"cancel\"],null]],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"clearfix\"],[12],[13],[2,\"\\n  \"],[1,[30,[36,4],null,[[\"model\"],[[35,2]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"route-action\",\"action\",\"model\",\"ui-button\",\"partial/webhook/form\"]}",
    "meta": {
      "moduleName": "frontend/pods/organizations/organization/webhooks/new/template.hbs"
    }
  });

  _exports.default = _default;
});