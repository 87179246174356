define("frontend/pods/current-activity/controller", ["exports", "frontend/mixins/modal"], function (_exports, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_modal.default, {
    store: Ember.inject.service(),
    chatService: Ember.inject.service('chat'),
    callService: Ember.inject.service('call'),
    authentication: Ember.inject.service(),
    searchValue: '',
    activeUserId: Ember.computed('authentication.activeUserId', {
      get: function get() {
        return parseInt(this.authentication.activeUserId);
      }
    }),
    availabilityMetric: Ember.computed('model.firstObject.availabilityMetric', function () {
      var value = this.get('model.firstObject.availabilityMetric') || 0;
      return Math.round(value * 100) / 100;
    }),
    availabilityMetricClass: Ember.computed('availabilityMetric', function () {
      var value = this.availabilityMetric;

      if (value < 1) {
        return "text-danger";
      } else {
        return "text-success";
      }
    }),
    canEavesdrop: Ember.computed.readOnly('callService.isPaused'),
    refreshInterval: 10000,
    refreshModel: function refreshModel() {
      var _this = this;

      var model = this.model;

      if (model) {
        var promise = !model.get('isUpdating') ? model.update() : Ember.RSVP.resolve();
        return promise.then(function () {
          return _this._setTimer();
        });
      }
    },
    activate: function activate() {
      return this._setTimer();
    },
    deactivate: function deactivate() {
      return this._clearTimer();
    },
    _setTimer: function _setTimer() {
      this._clearTimer();

      var interval = this.refreshInterval;
      return this.set('_timer', Ember.run.later(this, this.refreshModel, interval));
    },
    _clearTimer: function _clearTimer() {
      var timer = this._timer;

      if (timer) {
        return Ember.run.cancel(timer);
      }
    },
    actions: {
      abandon: function abandon(user) {
        var _this2 = this;

        var msg = "Are you user you want to abandon the call for ".concat(user.first_name, " ").concat(user.last_name, "?");
        return this.modal.confirm(msg).then(function (result) {
          if (!result) {
            return;
          }

          var adapter = _this2.store.adapterFor('application');

          return adapter.executeServerAction('call-session', undefined, 'abandon', {
            data: {
              user_id: user.id
            }
          }).then(function () {
            return _this2.alertsService.success('The call has been abandoned.  It will be removed from the list shortly.');
          });
        });
      },
      startConversation: function startConversation(user) {
        var _this3 = this;

        return this.store.find('user', user.id).then(function (u) {
          return _this3.chatService.startConversation(u);
        });
      },
      eavesdrop: function eavesdrop(user) {
        this.callService.eavesdrop(user.id);
      },
      searchTextChanged: function searchTextChanged(value) {
        this.set('searchValue', value);
      }
    }
  });

  _exports.default = _default;
});